import { RootState } from '../index';

export function mainPending(state: RootState) {
  return state.main.request.status === 1;
}

export function mainLoaded(state: RootState) {
  return state.main.request.status === 2;
}

export function mainFailure(state: RootState) {
  return state.main.request.status === 3;
}

export function mainData(state: RootState) {
  return state.main;
}
