import React, { useEffect, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { menuLinks, menuServices } from '@/utils/constants';
import { setTextOpacity } from '@/utils/methods';

const BreadCrumb: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const isHome = pathname === '/';
  const isServices = pathname.match(/^\/services\/\w{1,}\/?$/);
  const isSubServices = pathname.match(/^\/services\/\w{1,}\/\w{1,}/);

  const currentLink = useMemo(() => menuLinks.find(link => link.path === pathname), [pathname]);

  const isMain = !isHome && !isServices && !isSubServices && !!currentLink;

  const currentService = useMemo(
    () =>
      menuServices.find(link => {
        const newReg = new RegExp(link.path);
        const regPath = pathname.match(newReg);
        //console.log(regPath);
        if (!!regPath) return true;
        else return false;
      }),
    [pathname],
  );

  // eslint-disable-next-line prettier/prettier
  const currentSubService = useMemo(() => currentService?.subMenu?.find(link => link.path === pathname), [pathname, currentService]);

  useEffect(() => {
    const textId = '#menu-opacity-';
    setTextOpacity([textId + '0']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <section>
      {(isHome || !currentLink) && <></>}

      {isMain && (
        <div id="text-opacity-0" className="breadCrumbWrapper container textOpacity">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{currentLink?.name}</span>
              </span>
              <meta itemProp="position" content="2" />
            </li>
          </ul>
        </div>
      )}

      {!isHome && isServices && (
        <div id="menu-opacity-0" className="breadCrumbWrapper container textOpacity">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/services" className="link">
                <span itemProp="name">Услуги</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{currentService?.name}</span>
              </span>
              <meta itemProp="position" content="3" />
            </li>
          </ul>
        </div>
      )}

      {!isHome && isSubServices && (
        <div id="menu-opacity-0" className="breadCrumbWrapper container textOpacity">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/services" className="link">
                <span itemProp="name">Услуги</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to={`${currentService?.path}`} className="link">
                <span itemProp="name">{currentService?.name}</span>
              </Link>
              <meta itemProp="position" content="3" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{currentSubService?.name}</span>
              </span>
              <meta itemProp="position" content="4" />
            </li>
          </ul>
        </div>
      )}
    </section>
  );
};

export default BreadCrumb;
