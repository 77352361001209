import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Check } from '@mui/icons-material';
import { mainLoaded, mainPending } from '@/store/main/selectors';
import { sendData } from '@/store/main/slicer';
import { PropsModal } from './types';
import { modalStyle } from '@/utils/constants';
import { checkReg } from '@/utils/methods';
import { CloseBtn, FileForm } from '@/assets/images/Icons';

const callBackList = ['В ближайшее время', 'Сегодня', 'Завтра', 'Конкретное время...'];

// eslint-disable-next-line sonarjs/cognitive-complexity
const FeedbackModal: React.FC<PropsModal> = React.memo((props: PropsModal) => {
  const dispatch = useDispatch();
  const loading = useSelector(mainPending, shallowEqual);
  const loaded = useSelector(mainLoaded, shallowEqual);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [comment, setComment] = useState('');
  const [filePath, setFilePath] = useState('');
  const [file, setFile] = useState(null);
  const [checkboxWa, setCheckboxWa] = useState(false);
  const [checkboxTg, setCheckboxTg] = useState(false);
  const [callBack, setCallBack] = useState('');
  const [dialogError, setDialogError] = useState(false);

  const { settings, fullModal, sub, setSub } = props;
  const { openModal, setOpenModal } = settings;
  const isSub = !!sub && fullModal;
  const isPlatinum = sub === 'ПЛАТИНУМ';

  const handleClose = () => {
    setOpenModal(false);
    setName('');
    setPhone('');
    setEmail('');
    setCompany('');
    setComment('');
    setFilePath('');
    setFile('');
    setCheckboxWa(false);
    setCheckboxTg(false);
    setCallBack('');
    setDialogError(false);
    if (!!sub) setTimeout(() => setSub(null), 100);
  };

  useEffect(() => {
    if (!!loaded) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const changeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setDialogError(false);
  };

  const changePhone = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    setDialogError(false);
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const changeCompanyName = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  };

  const changeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const changeFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFilePath(e.target.value);
    setFile(e.target.files[0]);
    //setFile(e.target.files);
  };

  const changeCheckWa = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxWa(e.target.checked);
    setCheckboxTg(false);
  };

  const changeCheckTg = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxTg(e.target.checked);
    setCheckboxWa(false);
  };

  const changeCallBack = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const reg = val.match(/^Конкретное время...$/);

    if (!!reg) setCallBack('');
    else setCallBack(val);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const reg = checkReg([name, phone, company, email, comment]);
    const formData = new FormData();

    if (!!reg) {
      setDialogError(true);
      return;
    } else setDialogError(false);

    formData.append('wa', String(checkboxWa));
    formData.append('tg', String(checkboxTg));

    if (!!name && !!phone && !fullModal) {
      formData.append('title', '[Заявка на звонок]');
      formData.append('name', name);
      formData.append('phone', phone);
      dispatch(sendData(formData));
    }
    if (!!name && !!phone && !!fullModal) {
      if (isSub) {
        formData.append('title', '[Заявка с формы подписки]');
        formData.append('sub', sub);
        formData.append('callback', callBack);
      } else formData.append('title', '[Заявка с формы обратной связи]');

      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('company', company);
      formData.append('message', comment);
      formData.append('file', file);
      dispatch(sendData(formData));
      //console.log(Array.from(formData.entries()));
    }
  };

  const returnClassSub = () => {
    if (isSub && isPlatinum) return 'dialogFeedback dialogFeedback_sub dialogFeedback_platinum';
    if (isSub) return 'dialogFeedback dialogFeedback_sub';
    if (!isSub && !isPlatinum) return 'dialogFeedback';
  };

  return (
    <Dialog open={openModal} onClose={handleClose} PaperProps={{ sx: modalStyle }}>
      <div className={returnClassSub()}>
        <button className="closeBtn mainBtn" aria-label="close modal" onClick={handleClose}>
          {CloseBtn}
        </button>
        {isSub && <p className="subTitle">{sub}</p>}
        <DialogTitle className="dialogFeedback__heading">Форма заявки</DialogTitle>

        <DialogContent className="dialogFeedback__content">
          {/* <p className="text">
            Наш специалист связжется с вами и проконсультирует по интересующим вас вопросам
          </p> */}

          <form
            id="feedback-form"
            action="#"
            className="dialogFeedback__form"
            onSubmit={handleSubmit}
          >
            <input
              className="field"
              type="name"
              name="name"
              placeholder="Как к вам обращаться*"
              autoComplete="on"
              required
              pattern="[A-Za-zА-Яа-яЁё\s]+$"
              maxLength={30}
              value={name}
              onChange={changeName}
            />
            <input
              className="field"
              type="tel"
              name="phone"
              placeholder="Ваш контактный телефон*"
              autoComplete="on"
              required
              pattern="\+7\s?[\(]{0,1}[0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
              maxLength={25}
              value={phone}
              onChange={changePhone}
            />

            {isSub && isPlatinum && (
              <div className="wrapper">
                <input
                  id="form-call"
                  type="text"
                  className="field"
                  list="form-call-list"
                  name="callback"
                  placeholder="Когда вам перезвонить"
                  //required
                  //pattern="\D+$"
                  maxLength={30}
                  value={callBack}
                  onChange={changeCallBack}
                />
                <datalist id="form-call-list">
                  {(callBackList || []).map((item, idx) => (
                    <option key={idx} value={item} />
                  ))}
                </datalist>
              </div>
            )}

            {fullModal && !isSub && (
              <>
                <input
                  className="field"
                  type="text"
                  name="company"
                  placeholder="Название компании"
                  autoComplete="on"
                  //required
                  pattern="\D+$"
                  maxLength={50}
                  value={company}
                  onChange={changeCompanyName}
                />
                <input
                  className="field"
                  type="email"
                  name="email"
                  placeholder="Ваш e-mail"
                  autoComplete="on"
                  //required
                  pattern="(.{1,})@(.{1,})\.([A-z]{2,8})"
                  maxLength={50}
                  value={email}
                  onChange={changeEmail}
                />
                <textarea
                  className="textarea"
                  name="message"
                  id="message"
                  placeholder="Описание"
                  rows={4}
                  maxLength={500}
                  spellCheck="true"
                  value={comment}
                  onChange={changeComment}
                />
                <div className="fileWrapper">
                  <input
                    className="fieldFile"
                    id="file-form"
                    type="file"
                    name="file"
                    accept="image/*,.pdf,.doc,docx,.xml"
                    //multiple
                    value={filePath}
                    onChange={changeFile}
                  />
                  {!file ? (
                    <label htmlFor="file-form" className="fileLabel">
                      {FileForm}
                      <span>Прикрепление файла</span>
                    </label>
                  ) : (
                    <p className="fileSucsess">
                      <Check fontSize="medium" className="icon" />
                      <span>Файл добавлен</span>
                    </p>
                  )}
                </div>
              </>
            )}
          </form>

          <div className="dialogFeedback__writeMe">
            <span className="text">
              <Checkbox
                className="checkbox"
                id="writeme-wa"
                //required
                size="medium"
                value={checkboxWa}
                onChange={changeCheckWa}
                checked={checkboxWa}
                sx={{ color: '#2E353A', '&.Mui-checked': { color: '#2E353A' } }}
              />
              <label className="label" htmlFor="writeme-wa">
                Напишите мне в WhatsApp
              </label>
            </span>

            <span className="text">
              <Checkbox
                className="checkbox"
                id="writeme-tg"
                //required
                size="medium"
                value={checkboxTg}
                onChange={changeCheckTg}
                checked={checkboxTg}
                sx={{ color: '#2E353A', '&.Mui-checked': { color: '#2E353A' } }}
              />
              <label className="label" htmlFor="writeme-tg">
                Напишите мне в Telegram
              </label>
            </span>
          </div>

          <div className="dialogFeedback__submit">
            <p className="policy">
              Нажимая кнопку “Отправить”, Вы разрешаете обработку персональных данных и соглашаетесь
              с{' '}
              <a href="/policy" target="_blank" className="link">
                Политикой конфиденциальности
              </a>
            </p>
            <button type="submit" form="feedback-form" className="submitBtn mainBtn">
              Отправить
            </button>
          </div>

          {!!dialogError && <p className="dialogError">Введите необходимые данные корректно</p>}
        </DialogContent>

        {!!loading && (
          <div className="dialogLoading">
            <CircularProgress sx={{ color: '#010036' }} />
          </div>
        )}
      </div>
    </Dialog>
  );
});

export default FeedbackModal;
