import { MenuLink } from './types';

const mskTimeZone = 'Europe/Moscow';

// Get

export const getElement = (elId: string) => {
  const element = document.getElementById(elId);
  return {
    el: element,
    top: element?.getBoundingClientRect().top,
    height: element?.offsetHeight,
  };
};

export const getMenuLinks = (path: string, linksArr: Array<MenuLink>) => {
  linksArr.forEach((link: MenuLink) => {
    const newReg = new RegExp(link.path);
    const regPath = path.match(newReg);
    //console.log(regPath);

    if (path === link.path) {
      document.title = link.title;
      document.head.children.description.content = link.description;
    } else if (!!regPath) {
      const subMenuFinded = link?.subMenu?.find(subLink => subLink.path === path);
      //console.log(subMenuFinded);
      if (!!subMenuFinded) {
        document.title = subMenuFinded.title;
        document.head.children.description.content = link.description;
      }
    } else return;
  });
};

export const getScroll = () => {
  const scrollTop = document.documentElement.scrollTop;
  const headerTopEl = getElement('scroll-header').el;
  const headerEl = getElement('scroll-hidden').el;

  if (scrollTop > 10 && window.innerWidth > 667) headerTopEl.classList.add('headerScroll');

  if (scrollTop > 100) headerEl.style.opacity = '0.9';
  if (scrollTop > 130) headerEl.style.opacity = '0.8';
  if (scrollTop > 160) headerEl.style.opacity = '0.7';
  if (scrollTop > 190) headerEl.style.opacity = '0.6';
  if (scrollTop > 220) headerEl.style.opacity = '0.5';
  if (scrollTop > 250) headerEl.style.opacity = '0.4';
  if (scrollTop > 280) headerEl.style.opacity = '0.3';
  if (scrollTop > 310) headerEl.style.opacity = '0.2';
  if (scrollTop > 340) headerEl.style.opacity = '0.1';
  if (scrollTop > 370) headerEl.style.opacity = '0';

  if (scrollTop === 0 && window.innerWidth > 667) headerTopEl.classList.remove('headerScroll');
  if (scrollTop < 100) headerEl.style.opacity = '1';
};

// Set

export const setHeaderScroll = () => {
  const scrollTop = document.documentElement.scrollTop;
  const headerTopEl = getElement('header-scroll').el;

  if (scrollTop > 50 && window.innerWidth > 667) headerTopEl.classList.add('headerScroll');
  if (scrollTop === 0 && window.innerWidth > 667) headerTopEl.classList.remove('headerScroll');
};

export const setTextOpacity = (arrId: Array<string>) => {
  arrId.forEach(id => {
    document.querySelectorAll(id).forEach((el: HTMLElement) => {
      el.classList.add(`animOpacity-${id.slice(-1)}`);
    });
  });
};

export const removeTextOpacity = (arrId: Array<string>) => {
  arrId.forEach(id => {
    document.querySelectorAll(id).forEach((el: HTMLElement) => {
      el.classList.remove(`animOpacity-${id.slice(-1)}`);
    });
  });
};

// Regs

export function checkReg(arr: Array<string>) {
  const regAll = arr.filter(val => val.match(/^\s+$/));
  //console.log(regAll);
  if (!!regAll.length) return true;
}

// Format

export const formatVisitDate = (date: Date) => {
  //
  const options = {
    timeZone: mskTimeZone,
    day: 'numeric',
    month: 'long',
    //year: 'numeric',
    //hour: 'numeric',
    //minute: '2-digit',
  };

  const newDate = new Date(date);
  const currentDate = new Date();

  const newDateTime = new Date(
    `${newDate.getMonth() + 1}.${newDate.getDate()}.${newDate.getFullYear()}`,
  ).getTime();
  const currentDateTime = new Date(
    `${currentDate.getMonth() + 1}.${currentDate.getDate()}.${currentDate.getFullYear()}`,
  ).getTime();

  // console.log(newDateTime);
  // console.log(currentDateTime);

  if (!date) return '';

  if (newDateTime === currentDateTime) {
    return 'Сегодня';
  } else if (newDate.getFullYear() !== currentDate.getFullYear()) {
    const newOptions = { ...options, year: 'numeric' };
    return new Intl.DateTimeFormat('ru-RU', newOptions).format(newDate).toString();
  } else {
    return new Intl.DateTimeFormat('ru-RU', options).format(newDate).toString();
  }
};

//

export const genId = (value: string, adminId?: number) => {
  let n = 1;
  if (!!adminId) n = 0;
  // const d = Date.now().toString().slice(0, 1);
  // if (+d === 0 || +d === 1) n + 1;
  return `${value}-${n}${Date.now().toString()}`;
};
