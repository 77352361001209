/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, api } from '@store/index';

const initialState = {
  portfolioList: [],
  request: {
    status: 0,
    error: null,
  },
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    pending: state => {
      state.request.status = 1;
    },
    loaded: state => {
      state.request.status = 2;
    },
    failure: (state, { payload }) => {
      state.request.status = 3;
      state.request.error = payload;
    },
    setPortfolio: (state, { payload }) => {
      state.portfolioList = payload;
    },
  },
});

const { pending, loaded, failure, setPortfolio } = mainSlice.actions;
export default mainSlice.reducer;

export const sendData = (data: FormData) => async (dispatch: AppDispatch) => {
  dispatch(pending());

  try {
    const res = await fetch('/scripts/send_mail.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: data,
    });
    //console.log(res);
    if (!!res.ok) dispatch(loaded());
    else dispatch(failure(res.statusText));
    //
  } catch (err) {
    console.log(err);
    dispatch(failure(err));
  }
};

export const getPortfolioList = () => async (dispatch: AppDispatch) => {
  //dispatch(pending());
  const noCashe = +Date.now().toString().slice(3, -2);

  try {
    const res = await fetch(`${api.baseUrlData}portfolio.json?nocache=${noCashe}`);
    const data = await res.json();
    if (!!res.ok) dispatch(setPortfolio(data.cards));
    else console.log(res.status);
    //
  } catch (err) {
    console.log(err);
    //dispatch(failure(err));
  }
};
