export default class Admin {
  id: string;
  name: string;
  dbId?: string;
  email?: string;
  role?: string;
  registryDate?: string;
  visitDate?: string;
  static id: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
    // this.dbId = dbId;
    // this.name = name;
    // this.role = role;
    // this.visitDate = visitDate;
  }
}
