import React from 'react';
import { useLocation } from 'react-router-dom';
//
import { BeBlackBtn, TgBlackBtn, VkBlackBtn } from '@/assets/images/Icons';
//import Logo from '@/assets/images/BeffectLogo';

const Footer: React.FC = () => {
  const location = useLocation();

  const currentYear = new Date().getFullYear();
  const regAdminPath = location.pathname.match(/\/admin\/[a-z]+/);

  return (
    <footer id="footer-opacity-0">
      {!regAdminPath && (
        <div className="container">
          <div className="footerInfo">
            <div className="footerInfo__logo">
              <span>Beffect</span>
              {/* <span>Butterfly Effect</span> */}
            </div>

            <p className="footerInfo__copy">
              <span>&copy; {currentYear} Digital-агенство</span>
              <a href="/policy" className="link" target="_blank">
                Политика конфеденциальности
              </a>
            </p>
          </div>

          <div className="footerInfo">
            <div className="footerInfo__contacts">
              <h4 className="heading">Контакты</h4>
              <a href="mailto:agency@beffect.ru" className="link">
                agency@beffect.ru
              </a>
              <a href="tel:+79950117705" className="link">
                8(995)011-77-05
              </a>
            </div>

            <div className="footerInfo__social">
              <a
                href="https://vk.com/club217484784"
                target="_blank"
                className="link"
                rel="noreferrer"
              >
                {VkBlackBtn}
              </a>
              <a href="https://t.me/beffect_group" target="_blank" className="link" rel="noreferrer">
                {TgBlackBtn}
              </a>
              <a
                href="https://www.behance.net/beffect"
                target="_blank"
                className="link"
                rel="noreferrer"
              >
                {BeBlackBtn}
              </a>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
