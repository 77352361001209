/* eslint-disable no-console */
//export {};

export default class API {
  //baseUrl = '/scripts/admin/';
  baseUrl = 'https://beffect.ru/api/admin/';
  baseUrlData = '/scripts/data/';
  noCashe = +Date.now().toString().slice(3, -2);

  async login(formData: FormData) {
    try {
      return await fetch(this.baseUrl + 'login.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
        mode: 'no-cors',
      });
      //
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
    // return await fetch(this.baseUrl + 'login.php', {
    //   method: 'POST',
    //   //headers: { 'Content-Type': 'multipart/form-data' },
    //   body: formData,
    //   mode: 'no-cors',
    // });
  }

  async getAdmin(formData: FormData) {
    return await fetch(this.baseUrl + 'init.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
      mode: 'no-cors',
    });
  }

  async adminLogout() {
    return await fetch(this.baseUrl + 'logout.php');
  }

  async getLogin() {
    try {
      const res = await fetch(this.baseUrlData + 'login.json?nocache=' + this.noCashe);
      const data = await res.json();

      return { ok: res.ok, data: data.login };
      //
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async setData(formData: FormData) {
    try {
      return await fetch(this.baseUrl + 'write-data.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
        mode: 'no-cors',
      });
      //
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async setPhoto(formData: FormData) {
    try {
      return await fetch(this.baseUrl + 'photo/upload-photo.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
        mode: 'no-cors',
      });
      //
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }

  async deletePhoto(formData: FormData) {
    try {
      return await fetch(this.baseUrl + 'photo/unlink-photo.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
        mode: 'no-cors',
      });
      //
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
}
