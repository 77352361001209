/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
// import { onValue, ref, remove, set, update } from 'firebase/database';
// import { auth, db } from '../../services/firebase';
import { AppDispatch, api } from '..';
import Admin from '@/entities/admin';
import { TypeAdmin } from '@/utils/types';
//import { Order, Photo, Product, User, Verification } from '@/utils/mainTypes';
//import { formatRegistryDate, getCodeDb, getDates } from '@/utils/methods';

const initialState = {
  authed: false,
  currAdmin: Admin,
  loginInfo: null,
  allAdmins: [],
  //
  articles: [],
  catalog: [],
  orders: [],
  //
  supportMsgs: [],
  visits: {},
  //
  request: {
    status: 0,
    error: null,
  },
  requestData: {
    status: 0,
    error: null,
  },
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state.authed = payload;
      state.request.status = 2;
    },
    setAdmin: (state, { payload }) => {
      state.currAdmin = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    adminPending: state => {
      state.request.status = 1;
      state.request.error = null;
    },
    adminSuccess: state => {
      state.request.status = 2;
      state.request.error = null;
    },
    adminFailure: (state, { payload }) => {
      state.request.error = payload;
      state.request.status = 3;
    },
    setLogin: (state, { payload }) => {
      state.loginInfo = payload;
      //state.request.status = 2;
    },
    // Data
    dataPending: state => {
      state.requestData.status = 1;
      state.requestData.error = null;
    },
    dataSuccess: state => {
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    dataFailure: (state, { payload }) => {
      state.requestData.error = payload;
      state.requestData.status = 3;
    },
    // setUsers: (state, { payload }) => {
    //   state.allUsers = payload;
    //   state.requestData.status = 2;
    //   state.requestData.error = null;
    // },
    setAdmins: (state, { payload }) => {
      state.allAdmins = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    // Catalog
    setCatalog: (state, { payload }) => {
      state.catalog = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setOrders: (state, { payload }) => {
      state.orders = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    //
    setSupportMsgs: (state, { payload }) => {
      state.supportMsgs = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setVisits: (state, { payload }) => {
      state.visits[payload.key] = payload.value;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    clear: state => {
      state.authed = false;
      state.currAdmin = Admin;
      state.loginInfo = null;
      state.allAdmins = [];
      state.articles = [];
      state.orders = [];
      state.catalog = [];
      state.supportMsgs = [];
      state.visits = {};
      state.request.status = 0;
      state.request.error = null;
      state.requestData.status = 0;
      state.requestData.error = null;
    },
  },
});

const {
  setAuth,
  setAdmin,
  adminPending,
  adminFailure,
  setLogin,
  dataPending,
  dataSuccess,
  dataFailure,
  clear,
} = adminSlice.actions;
export default adminSlice.reducer;

// Auth

export const initAdmin = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(adminPending());

  const data = new FormData();
  data.append('id', id);

  try {
    const res = await api.getAdmin(data);
    console.log(res);

    if (res.ok && res.status === 200) {
      dispatch(setAuth(true));
      //dispatch(setAdmin(newAdmin));
    } else if (res.ok && res.status === 204) {
      //dispatch(setAuth(false));
      dispatch(clear());
      dispatch(adminFailure('Ошибка: Неверный логин или пароль'));
    } else dispatch(adminFailure('Ошибка сервера'));
    //
  } catch (err) {
    console.log(err);
    dispatch(adminFailure('Ошибка: ' + err.message));
  }
};

export const adminLogin = (data: TypeAdmin) => async (dispatch: AppDispatch) => {
  dispatch(adminPending());

  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('passw', data.passw);
  formData.append('id', data.id);

  try {
    const res = await api.login(formData);
    console.log(res);

    if (res.ok && res.status === 200) {
      const newAdmin = new Admin(data.id, data.name);
      dispatch(setAuth(true));
      dispatch(setAdmin(newAdmin));
    } else if (res.ok && res.status === 204) {
      dispatch(setAuth(false));
      dispatch(adminFailure('Ошибка: Неверный логин или пароль'));
    } else dispatch(adminFailure('Ошибка сервера'));
    //
  } catch (err) {
    console.log(err);
    dispatch(adminFailure('Ошибка: ' + err.message));
  }
};

//

// export const getVisitsDb = () => (dispatch: AppDispatch) => {
//   dispatch(dataPending());

//   const year = getDates().year;
//   const month = getDates().month;
//   const day = getDates().day;
//   const dbRefdDay = ref(db, `visits/${year}/${month}/${day}`);
//   const dbRefMonth = ref(db, `visits/${year}/${month}`);
//   const dbRefYear = ref(db, `visits/${year}`);

//   onValue(
//     dbRefdDay,
//     snapshot => {
//       const amount = snapshot.val() || 0;
//       const obj = {
//         key: 'day',
//         value: amount,
//       };

//       dispatch(setVisits(obj));
//     },
//     err => {
//       console.log(err);
//       dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
//     },
//   );

//   onValue(
//     dbRefMonth,
//     snapshot => {
//       const list = Object.values(snapshot.val() || {});
//       //console.log(list);
//       const amount = list.reduce((partialSum, a) => +partialSum + +a, 0);
//       const obj = {
//         key: 'month',
//         value: amount,
//       };

//       dispatch(setVisits(obj));
//     },
//     err => {
//       console.log(err);
//       dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
//     },
//   );

//   onValue(
//     dbRefYear,
//     snapshot => {
//       let amount = 0;
//       const listMonth = Object.values(snapshot.val() || {});
//       //console.log(listMonth);
//       listMonth.forEach(item => {
//         const listItem = Object.values(item || {});
//         //console.log(listItem);
//         amount = amount + listItem.reduce((partialSum, a) => +partialSum + +a, 0);
//       });
//       const obj = {
//         key: 'year',
//         value: amount,
//       };

//       dispatch(setVisits(obj));
//     },
//     err => {
//       console.log(err);
//       dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
//     },
//   );
// };

// Login Info

export const getLoginInfo = () => async (dispatch: AppDispatch) => {
  //dispatch(pending());

  try {
    const res = await api.getLogin();
    //console.log(res.data);

    if (res.ok) dispatch(setLogin(res.data));
    else dispatch(setLogin(null));
    //
  } catch (err) {
    console.log(err);
    dispatch(adminFailure('Ошибка: ' + err.message));
  }
};

export const changeLoginInfo = (adminId: string) => async (dispatch: AppDispatch) => {
  //dispatch(pending());
  const date = Date.now();
  const obj = {
    id: adminId || '0',
    date,
  };

  const formData = new FormData();
  formData.append('name', 'login');
  formData.append('data', JSON.stringify({ login: obj }));
  console.log(JSON.stringify({ login: obj }));

  try {
    const res = await api.setData(formData);
    console.log(res);
    //
  } catch (err) {
    console.log(err);
    dispatch(adminFailure('Ошибка: ' + err.message));
  }
};

// Data

export const changePortfolioCard = (data: Array<object>) => async (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const formData = new FormData();
  formData.append('name', 'portfolio');
  formData.append('data', JSON.stringify({ cards: data }));
  console.log(JSON.stringify({ cards: data }));

  try {
    const res = await api.setData(formData);
    //console.log(res);
    if (res.ok) dispatch(dataSuccess());
    else dispatch(dataFailure('Ошибка: ' + res.statusText));
    //
  } catch (err) {
    console.log(err);
    dispatch(dataFailure('Ошибка: ' + err.message));
  }
};

export const addPortfolioPhoto = (data: FormData) => async (dispatch: AppDispatch) => {
  dispatch(dataPending());

  try {
    const res = await api.setPhoto(data);
    //console.log(res);
    if (res.ok) dispatch(dataSuccess());
    else dispatch(dataFailure('Ошибка: Фото не загрузилось'));
    //
  } catch (err) {
    console.log(err);
    dispatch(dataFailure('Ошибка: ' + err.message));
  }
};

export const delPortfolioPhoto = (data: FormData) => async (dispatch: AppDispatch) => {
  dispatch(dataPending());

  try {
    const res = await api.deletePhoto(data);
    //console.log(res);
    if (res.ok) dispatch(dataSuccess());
    else dispatch(dataFailure('Ошибка: ' + res.statusText));
    //
  } catch (err) {
    console.log(err);
    dispatch(dataFailure('Ошибка: ' + err.message));
  }
};

// Exit

export const adminExit = () => async (dispatch: AppDispatch) => {
  //
  try {
    const res = await api.adminLogout();
    console.log(res);
    dispatch(clear());
    //
  } catch (err) {
    console.log(err);
    dispatch(adminFailure('Ошибка: ' + err.message));
  }
};
