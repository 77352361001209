import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import API from '@/services/api';
import mainSlicer from './main/slicer';
import adminSlicer from './admin/slicer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['main', 'admin'],
};

const adminPersistConfig = {
  key: 'admin',
  storage,
  whitelist: ['authed', 'currAdmin'],
};

const rootReducer = combineReducers({
  main: mainSlicer,
  admin: persistReducer(adminPersistConfig, adminSlicer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  //devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const api = new API();
